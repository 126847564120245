import axios from '@axios'
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {

      return new Promise((resolve, reject) => {
        axios
          .post(`${serverUri}/drivers/getScheduleData`, {company_id :getUserData().company_id,domicile:JSON.parse(localStorage.getItem('domicile')),vehicle:JSON.parse(localStorage.getItem('vehicle')),route:JSON.parse(localStorage.getItem('route')),driver:JSON.parse(localStorage.getItem('driver'))})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {

      event.company_id = getUserData().company_id;

      return new Promise((resolve, reject) => {
        axios
          .post(`${serverUri}/drivers/addScheduleData`, { event})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      event.company_id = getUserData().company_id;

      var getDay = moment(new Date(event.start)).format("ddd");

      var getDayValue = {};

      if(getDay == 'Mon')
      {
        getDayValue.day     = event.extendedProps.mon_value; 
        getDayValue.tractor = event.extendedProps.mon_tractor_id; 

      } else if (getDay == 'Tue')
      {
        getDayValue.day     = event.extendedProps.tue_value; 
        getDayValue.tractor = event.extendedProps.tue_tractor_id; 

      } else if (getDay == 'Wed')
      {
        getDayValue.day     = event.extendedProps.wed_value; 
        getDayValue.tractor = event.extendedProps.wed_tractor_id; 

      } else if (getDay == 'Thu')
      {
        getDayValue.day     = event.extendedProps.thu_value; 
        getDayValue.tractor = event.extendedProps.thu_tractor_id; 

      } else if (getDay == 'Fri')
      {
        getDayValue.day     = event.extendedProps.fri_value; 
        getDayValue.tractor = event.extendedProps.fri_tractor_id; 

      } else if (getDay == 'Sat')
      {
        getDayValue.day     = event.extendedProps.sat_value; 
        getDayValue.tractor = event.extendedProps.sat_tractor_id; 

      } else if (getDay == 'Sun') {
  
        getDayValue.day     = event.extendedProps.sun_value; 
        getDayValue.tractor = event.extendedProps.sun_tractor_id; 

      }

      event.extendedProps.dayData = getDayValue;
      

      return new Promise((resolve, reject) => {
        axios
          .post(`${serverUri}/drivers/updateScheduleData/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${serverUri}/drivers/deleteScheduleData/`, { id : id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
