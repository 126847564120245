<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ eventLocal.id ? "Update" : "Add" }} Schedule</h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer delete_icon"
              @click="
                $emit('remove-event');
                hide();
              "
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit), resetCurrentData()"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Domicile"
              rules="required"
            >
              <b-form-group
                label="Domicile"
                label-for="domicile"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.domicile"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="domiciles"
                  label="name"
                  input-id="domicile"
                  :clearable="false"
                  @input="DomicileSelect()"
                >
                  <template #option="{ name }">
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <span
              style="cursor: pointer; color: #7367f0"
              @click="addRoute"
              v-if="selectDomicile != '' || eventLocal.extendedProps.route"
              >+ New Route
            </span>

            <!-- Route -->

            <validation-provider
              #default="validationContext"
              name="Route"
              rules="required"
            >
              <b-form-group
                label="Route"
                label-for="route"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.route"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="routes"
                  label="name"
                  input-id="route"
                  :clearable="false"
                  @input="RouteSelect()"
                >
                  <template #option="{ name }">
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row v-if="eventOriginName || eventLocal.extendedProps.route">
              <span class="align-middle ml-50 edit_icon" @click="updateRoute"
                ><feather-icon icon="EditIcon" /> Edit Route</span
              >

              <!-- <span class="edit_icon" @click="updateRoute">Edit Route </span> -->

              <b-col cols="12">
                <b-form-group
                  label="Origin"
                  label-for="h-origin-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-origin-name"
                    v-model="eventLocal.extendedProps.eventOriginName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Destination"
                  label-for="h-destination-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-destination-name"
                    v-model="eventLocal.extendedProps.eventDestinationName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Distance"
                  label-for="h-distance"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-distance-name"
                    v-model="eventLocal.extendedProps.eventDistance"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Drivers -->

            <validation-provider
              #default="validationContext"
              name="Driver"
              rules="required"
            >
              <b-form-group label="Driver" label-for="driver">
                <v-select
                  v-model="eventLocal.extendedProps.driver"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="drivers"
                  label="name"
                  :clearable="false"
                  input-id="driver"
                >
                  <template #option="{ avatar, name }">
                    <b-avatar size="sm" :src="avatar" />
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    />
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col cols="12">
                <h5 class="mb-2 mt-2">TIMING</h5>

                <b-row class="ml-1">
                  <b-col
                    cols="6"
                    class="justify-content-center d-flex"
                    v-if="!eventLocal.id && !eventLocal.start"
                  >
                    <b-form-checkbox
                      @input="onSameTime"
                      v-model="eventLocal.extendedProps.sameTime"
                      value="sametime"
                      class="custom-control-primary"
                    >
                      <p style="font-size: 12px">
                        Select this checkbox to apply same time for all days
                      </p>
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    cols="6"
                    class="justify-content-center d-flex"
                    v-if="!eventLocal.id && !eventLocal.start"
                  >
                    <b-form-checkbox
                      @input="onSameTractor"
                      v-model="eventLocal.extendedProps.sameTractor"
                      value="sametractor"
                      class="custom-control-primary"
                    >
                      <p style="font-size: 12px">
                        Select this checkbox to apply same tractor for all days
                      </p>
                    </b-form-checkbox>
                  </b-col>

                  <b-col class="mt-1" cols="5">
                    <validation-provider
                      #default="validationContext"
                      name="At Least one same time"
                      rules="required"
                      v-if="!same_time_selected"
                    >
                      <b-form-group
                        label="At Least one same time"
                        label-for="least_time"
                      >
                        <b-form-input
                          id="least_time"
                          v-model="singleTime"
                          :state="getValidationState(validationContext)"
                          style="display: none"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col class="mt-1" cols="5">
                    <validation-provider
                      #default="validationContext"
                      name="At Least one same tractor"
                      rules="required"
                      v-if="!same_tractor_selected"
                    >
                      <b-form-group
                        label="At Least one same tractor"
                        label-for="least_tractor"
                      >
                        <b-form-input
                          id="least_tractor"
                          v-model="singleTractor"
                          :state="getValidationState(validationContext)"
                          style="display: none"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- <b-col class="mt-1" cols="2"> Saturday </b-col> -->
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.sat_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Sat') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Saturday Time"
                      :rules="!same_time_selected ? '' : 'required'"
                    >
                      <b-form-group label="Saturday Time" label-for="saturday">
                        <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('sat')"
                        />
                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            clearable: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            wrap: true,
                          }"
                          :clearable="true"
                          input-id="saturday"
                          @input="onChangeTime('sat')"
                        />
                       

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.sat_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Sat') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Saturday Tractor"
                      :rules="!same_tractor_selected ? '' : 'required'"
                    >
                      <b-form-group
                        label="Saturday Tractor"
                        label-for="saturday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="saturday_tractor"
                          @input="onChangeTractor('sat')"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Sunday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.sun_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Sun') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                  <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('sun')"

                        />
                    <validation-provider
                      #default="validationContext"
                      name="Sunday Time"
                      :rules="!same_time_selected ? sun_error : 'required'"
                    >
                      <b-form-group label="Sunday Time" label-for="sunday">
                        <flat-pickr
                          v-model="eventLocal.extendedProps.sun_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="sunday"
                          @input="onChangeTime('sun')"
                          v-if="!same_time_selected"
                        />

                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="sunday"
                          v-if="same_time_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.sun_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Sun') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Sunday Tractor"
                      :rules="
                        !same_tractor_selected ? sun_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Sunday Tractor"
                        label-for="sunday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.sun_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          input-id="sunday_tractor"
                          :options="tractorsOption"
                          @input="onChangeTractor('sun')"
                          v-if="!same_tractor_selected"
                        />

                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          input-id="sunday_tractor"
                          :options="tractorsOption"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Monday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.mon_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Mon') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Monday Time"
                      :rules="!same_time_selected ? mon_error : 'required'"
                    >
                    <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('mon')"

                        />
                      <b-form-group label="Monday Time" label-for="monday_time">
                        <flat-pickr
                          v-model="eventLocal.extendedProps.mon_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="monday_time"
                          @input="onChangeTime('mon')"
                          v-if="!same_time_selected"
                        />

                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="monday_time"
                          @input="onChangeTime"
                          v-if="same_time_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.mon_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Mon') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Monday Tractor"
                      :rules="
                        !same_tractor_selected ? mon_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Monday Tractor"
                        label-for="monday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.mon_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="monday_tractor"
                          @input="onChangeTractor('mon')"
                          v-if="!same_tractor_selected"
                        />

                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="monday_tractor"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Tuesday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.tue_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Tue') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                  <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('tue')"

                        />
                    <validation-provider
                      #default="validationContext"
                      name="Tuesday Time"
                      :rules="!same_time_selected ? tue_error : 'required'"
                    >
                      <b-form-group
                        label="Tuesday Time"
                        label-for="tuesday_time"
                      >
                        <flat-pickr
                          v-model="eventLocal.extendedProps.tue_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="tuesday_time"
                          @input="onChangeTime('tue')"
                          v-if="!same_time_selected"
                        />
                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="tuesday_time"
                          @input="onChangeTime"
                          v-if="same_time_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.tue_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Tue') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Tuesday Tractor"
                      :rules="
                        !same_tractor_selected ? tue_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Tuesday Tractor"
                        label-for="tuesday_time"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.tue_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="tuesday_tractor"
                          @input="onChangeTractor('tue')"
                          v-if="!same_tractor_selected"
                        />
                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="tuesday_tractor"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Wednesday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.wed_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Wed') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                  <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('wed')"

                        />
                    <validation-provider
                      #default="validationContext"
                      name="Wednesday Time"
                      :rules="!same_time_selected ? wed_error : 'required'"
                    >
                      <b-form-group
                        label="Wednesday Time"
                        label-for="wednesday_time"
                      >
                        <flat-pickr
                          v-model="eventLocal.extendedProps.wed_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="wednesday_time"
                          @input="onChangeTime('wed')"
                          v-if="!same_time_selected"
                        />

                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="wednesday_time"
                          @input="onChangeTime"
                          v-if="same_time_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.wed_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Wed') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Wednesday Tractor"
                      :rules="
                        !same_tractor_selected ? wed_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Wednesday Tractor"
                        label-for="wednesday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.wed_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="wednesday_tractor"
                          @input="onChangeTractor('wed')"
                          v-if="!same_tractor_selected"
                        />

                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="wednesday_tractor"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Thursday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.thu_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Thu') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                  <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('thu')"

                        />
                    <validation-provider
                      #default="validationContext"
                      name="Thursday Time"
                      :rules="!same_time_selected ? thu_error : 'required'"
                    >
                      <b-form-group
                        label="Thursday Time"
                        label-for="thursday_time"
                      >
                        <flat-pickr
                          v-model="eventLocal.extendedProps.thu_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="thursday_time"
                          @input="onChangeTime('thu')"
                          v-if="!same_time_selected"
                        />
                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="thursday_time"
                          @input="onChangeTime"
                          v-if="same_time_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.thu_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Thu') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Thursday Tractor"
                      :rules="
                        !same_tractor_selected ? thu_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Thursday Tractor"
                        label-for="thursday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.thu_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="thursday_tractor"
                          @input="onChangeTractor('thu')"
                          v-if="!same_tractor_selected"
                        />
                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="thursday_tractor"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col class="mt-1" cols="2"> Friday </b-col> -->

                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.fri_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Fri') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                  <feather-icon
                          class="ml-1 cursor-pointer pull-right time_icon"
                          icon="XIcon"
                          size="12"
                          @click="clearTime('fri')"

                        />
                  
                    <validation-provider
                      #default="validationContext"
                      name="Friday Time"
                      :rules="!same_time_selected ? fri_error : 'required'"
                    >
                      <b-form-group label="Friday Time" label-for="friday_time">
                        <flat-pickr
                          v-model="eventLocal.extendedProps.fri_value"
                          class="form-control schedule_time_pick"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="friday_time"
                          @input="onChangeTime('fri')"
                          v-if="!same_time_selected"
                        />
                        <flat-pickr
                          v-model="eventLocal.extendedProps.sat_value"
                          class="form-control schedule_time_pick"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                          }"
                          input-id="friday_time"
                          @input="onChangeTime"
                          v-if="same_time_selected"
                        />
                        
                      </b-form-group>
                     
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="5"
                    v-if="
                      (eventLocal.id && eventLocal.extendedProps.fri_value) ||
                      (!eventLocal.id &&
                        checkEvent(eventLocal.start) == 'Fri') ||
                      (!eventLocal.id && checkEvent(eventLocal.start) == '')
                    "
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Friday Tractor"
                      :rules="
                        !same_tractor_selected ? fri_tractor_error : 'required'
                      "
                    >
                      <b-form-group
                        label="Friday Tractor"
                        label-for="friday_tractor"
                      >
                        <v-select
                          v-model="eventLocal.extendedProps.fri_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="friday_tractor"
                          @input="onChangeTractor('fri')"
                          v-if="!same_tractor_selected"
                        />

                        <v-select
                          v-model="eventLocal.extendedProps.sat_tractor_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="tractorsOption"
                          input-id="friday_tractor"
                          @input="onChangeTractor"
                          v-if="same_tractor_selected"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-modal
      size="lg"
      id="modal-add-route"
      ref="modal-add-route"
      :hide-footer="true"
      title="Add Route"
    >
      <b-row class="match-height">
        <b-col cols="12">
          <b-form-group
            label="Route"
            label-for="h-route-name"
            label-cols-md="4"
          >
            <b-form-input id="h-route-name" v-model="routeName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Origin"
            label-for="h-origin-name"
            label-cols-md="4"
          >
            <b-form-input id="h-origin-name" v-model="originName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Destination"
            label-for="h-destination-name"
            label-cols-md="4"
          >
            <b-form-input id="h-destination-name" v-model="destinationName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Distance"
            label-for="h-distance"
            label-cols-md="4"
          >
            <b-form-input id="h-distance-name" v-model="distance" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Start Timing Slots -->

      <b-row>
        <b-col cols="12">
          <b-button @click="addRouteData" v-if="!this.routeId"
            >Submit
          </b-button>
          <b-button @click="updateRouteData" v-if="this.routeId"
            >Update
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import axios from "axios";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import useCalendar from "../useCalendar";
import Vue from "vue";

window.EventBus = new Vue();

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    domicleDropdown: {
      type: String,
      require: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      domiciles: [],
      selectDomicile: "",
      singleTime: "",
      singleTractor: "",
      routes: [],
      vehicleOption: [],
      tractorsOption: [],
      eventDestinationName: "",
      eventOriginName: "",
      eventDistance: "",
      drivers: [],
      routeId: "",
      routeName: "",
      distance: "",
      originName: "",
      destinationName: "",
      sat_value: null,
      sun_value: null,
      mon_value: null,
      tue_value: null,
      wed_value: null,
      thu_value: null,
      fri_value: null,
      sat_tractor_id: "OFF",
      sun_tractor_id: "OFF",
      mon_tractor_id: "OFF",
      tue_tractor_id: "OFF",
      wed_tractor_id: "OFF",
      thu_tractor_id: "OFF",
      fri_tractor_id: "OFF",

      sat_error: "",
      sun_error: "",
      mon_error: "",
      tue_error: "",
      wed_error: "",
      thu_error: "",
      fri_error: "",
      sat_tractor_error: "",
      sun_tractor_error: "",
      mon_tractor_error: "",
      tue_tractor_error: "",
      wed_tractor_error: "",
      thu_tractor_error: "",
      fri_tractor_error: "",

      same_time_selected: false,
      same_tractor_selected: false,
    };
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    console.log(props.isEventHandlerSidebarActive);

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
  beforeDestroy() {
    EventBus.$off("getDropDownData");
    EventBus.$off("resetFormData");
  },

  created() {

    localStorage.removeItem('start_week');
    localStorage.removeItem('end_week');
    // get domiciles
    axios
      .post(`${serverUri}/schedule/getdomicile`, {
        company: getUserData().company_id,
      })
      .then((res) => {
        const Response = res.data.filter((item) => item.domicile?.length > 0);

        Response.map((item) => {
          this.domiciles.push({ title: item.domicile, name: item.domicile });
        });
        // this.tractorsOption.push({ title: 'OFF' })
      });

    EventBus.$on("getDropDownData", (item) => {
      this.eventLocal.extendedProps = item.data.extendedProps;
      this.selectDomicile = item.data.extendedProps.domicile.name;
      this.eventLocal.extendedProps.domicile = item.data.extendedProps.domicile;

      this.DomicileSelect();
      this.onChangeTractor(item.day.toLowerCase());
      this.onChangeTime(item.day.toLowerCase());

      this.same_tractor_selected = false;
      this.same_time_selected = false;

      console.log("-1--1");
    });

    EventBus.$on("resetFormData", (item) => {
      if (item.type == "add_all" || item.type == "add") {

        this.sat_value = null;
        this.sun_value = null;
        this.mon_value = null;
        this.tue_value = null;
        this.wed_value = null;
        this.thu_value = null;
        this.fri_value = null;
        this.sat_tractor_id = "OFF";
        this.sun_tractor_id = "OFF";
        this.mon_tractor_id = "OFF";
        this.tue_tractor_id = "OFF";
        this.wed_tractor_id = "OFF";
        this.thu_tractor_id = "OFF";
        this.fri_tractor_id = "OFF";

        this.sat_error = "";
        this.sun_error = "";
        this.mon_error = "";
        this.tue_error = "";
        this.wed_error = "";
        this.thu_error = "";
        this.fri_error = "";
        this.sat_tractor_error = "";
        this.sun_tractor_error = "";
        this.mon_tractor_error = "";
        this.tue_tractor_error = "";
        this.wed_tractor_error = "";
        this.thu_tractor_error = "";
        this.fri_tractor_error = "";

        this.same_time_selected = false;
        this.same_tractor_selected = false;
        this.singleTime = "";
        this.singleTractor = "";
        this.eventLocal.extendedProps.route = "";
        this.eventOriginName = "";
        this.selectDomicile = "";

      } else {
        console.log(item.type);

        this.same_tractor_selected = false;
        this.same_time_selected = false;
      }
    });
  },
  methods: {
    checkEvent(value) {
      var e = new Date(value);

      var StartDay = moment(e.setDate(e.getDate())).format("ddd");

      return value == "" ? "" : StartDay;
    },
    resetCurrentData() {
      // this.same_time_selected = false;
      // this.same_tractor_selected = false;
      // this.$refs.refFormObserver.validate().then((isValid) => {
      //   if (isValid) {
      //     this.selectDomicile = "";
      //     this.eventOriginName = "";
      //   }
      // });
    },
    handleSubmitNew() {
      var startWeek = moment().subtract(0, "weeks").startOf("isoWeek");
      var endWeek = moment().subtract(0, "weeks").endOf("isoWeek");

      var s = new Date(startWeek);
      var e = new Date(endWeek);

      var StartDay = moment(s.setDate(s.getDate() - 2)).format("YYYY-MM-DD");

      var NewDay = new Date(StartDay);

      var Timings = [
        {
          day: StartDay + " " + this.sat_value,
          tractor_id: this.sat_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.sun_value,
          tractor_id: this.sun_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.mon_value,
          tractor_id: this.mon_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.tue_value,
          tractor_id: this.tue_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.wed_value,
          tractor_id: this.wed_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.thu_value,
          tractor_id: this.thu_tractor_id,
        },
        {
          day:
            moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
            " " +
            this.fri_value,
          tractor_id: this.fri_tractor_id,
        },
      ];

      if (!this.selectDomicile) {
        this.showToast("warning", "warning", "Please select domicile");
      }

      if (!this.eventLocal.extendedProps.route) {
        this.showToast("warning", "warning", "Please select route");
      }

      if (!this.eventLocal.extendedProps.driver) {
        this.showToast("warning", "warning", "Please select driver");

        return false;
      } else if (
        this.sat_tractor_id == "OFF" ||
        this.fri_tractor_id == "OFF" ||
        this.thu_tractor_id == "OFF" ||
        this.thu_tractor_id == "OFF" ||
        this.wed_tractor_id == "OFF" ||
        this.tue_tractor_id == "OFF" ||
        this.mon_tractor_id == "OFF"
      ) {
        this.showToast("warning", "warning", "Please select tractor");
        return false;
      } else if (
        this.sat_value == "" ||
        this.fri_value == null ||
        this.thu_value == null ||
        this.thu_value == null ||
        this.wed_value == null ||
        this.tue_value == null ||
        this.mon_value == null
      ) {
        this.showToast("warning", "warning", "Please select day time");
        return false;
      }

      for (var i = 0; i < Timings.length; i++) {
        axios
          .post(`${serverUri}/drivers/addScheduleData`, {
            company_id: getUserData().company_id,
            domicile: this.selectDomicile,
            route: this.eventLocal.extendedProps.route,
            driver: this.eventLocal.extendedProps.driver,
            distance: this.eventDistance,
            origin: this.eventOriginName,
            destination: this.evenDdestinationName,
            tractor: Timings[i].tractor_id,
            start: Timings[i].day,
          })
          .then((res) => {
            if (res.data.status) {
              this.showToast("success", "success", res.data.message);
            } else {
              this.showToast("success", "success", res.data.message);
            }

            // console.log(i);
            // if (i == 6) {
            // const { fetchEvents } = useCalendar();
            // this.isEventHandlerSidebarActive == false;
            EventBus.$emit("fetch-events");
            EventBus.$emit("isEventHandlerSidebarActive", false);
            // fetchEvents();
            // }
          });
      }
      // this.$router.go()
      // this.$router.push({ name: "apps-calendar"});
    },

    onChangeTractor(day) {
      if (day == "sat") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.sat_tractor_id,
        };
        this.sat_error = "required";
      } else if (day == "sun") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.sun_tractor_id,
        };
        this.sun_error = "required";
      } else if (day == "mon") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.mon_tractor_id,
        };
        this.mon_error = "required";
      } else if (day == "tue") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.tue_tractor_id,
        };
        this.tue_error = "required";
      } else if (day == "wed") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.wed_tractor_id,
        };
        this.wed_error = "required";
      } else if (day == "thu") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.thu_tractor_id,
        };
        this.thu_error = "required";
      } else if (day == "fri") {
        this.singleTractor = {
          title: this.eventLocal.extendedProps.fri_tractor_id,
        };
        this.fri_error = "required";
      }
    },
    clearTime(day) {
      if (day == "sat") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime = '';
          this.eventLocal.extendedProps.sat_value =  '';
          this.sat_error = "required";

        } else {

          this.singleTime = '00:00';
          this.eventLocal.extendedProps.sat_value =  '00:00';
        }

      } else if (day == "sun") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime = '';
          this.eventLocal.extendedProps.sat_value =  '';
          this.sun_error = "required";

        } else {

          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.sun_value =  '00:00';          

        }


      } else if (day == "mon") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime =  '';
          this.eventLocal.extendedProps.mon_value =  '';
          this.mon_error = "required";

        } else {

          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.mon_value =  '00:00';
        }
       

      } else if (day == "tue") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime =  '';
          this.eventLocal.extendedProps.tue_value =  '';
          this.tue_error = "required";

        } else {
          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.tue_value =  '00:00';
        }
       
      } else if (day == "wed") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime =  '';
          this.eventLocal.extendedProps.wed_value =  '';
          this.wed_error = "required";

        } else {

          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.wed_value =  '00:00';

        }
       
        // 

      } else if (day == "thu") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime =  '';
          this.eventLocal.extendedProps.thu_value =  '';
          this.thu_error = "required";

        } else {

          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.thu_value =  '00:00';

        }
          
      } else if (day == "fri") 
      {
        if(!this.eventLocal.id)
        {
          this.singleTime =  '';
          this.eventLocal.extendedProps.fri_value =  '';
          this.fri_error = "required";

        } else {

          this.singleTime =  '00:00';
          this.eventLocal.extendedProps.fri_value =  '00:00';

        }
         
      }
    },
    onChangeTime(day) {
      if (day == "sat") {
        this.singleTime = this.eventLocal.extendedProps.sat_value;
        this.sat_tractor_error = "required";
      } else if (day == "sun") {
        this.singleTime = this.eventLocal.extendedProps.sun_value;
        this.sun_tractor_error = "required";
      } else if (day == "mon") {
        this.singleTime = this.eventLocal.extendedProps.mon_value;
        this.mon_tractor_error = "required";
      } else if (day == "tue") {
        this.singleTime = this.eventLocal.extendedProps.tue_value;
        this.tue_tractor_error = "required";
      } else if (day == "wed") {
        this.singleTime = this.eventLocal.extendedProps.wed_value;
        this.wed_tractor_error = "required";
      } else if (day == "thu") {
        this.singleTime = this.eventLocal.extendedProps.thu_value;
        this.thu_tractor_error = "required";
      } else if (day == "fri") {
        this.singleTime = this.eventLocal.extendedProps.fri_value;
        this.fri_tractor_error = "required";
      }
    },
    onSameTime(id) {
      if (id === "sametime") {
        this.same_time_selected = true;
        this.eventLocal.extendedProps.sun_value =
          this.eventLocal.extendedProps.sat_value;
        this.eventLocal.extendedProps.mon_value =
          this.eventLocal.extendedProps.sat_value;
        this.eventLocal.extendedProps.tue_value =
          this.eventLocal.extendedProps.sat_value;
        this.eventLocal.extendedProps.wed_value =
          this.eventLocal.extendedProps.sat_value;
        this.eventLocal.extendedProps.thu_value =
          this.eventLocal.extendedProps.sat_value;
        this.eventLocal.extendedProps.fri_value =
          this.eventLocal.extendedProps.sat_value;
      } else {
        this.same_time_selected = false;
        this.eventLocal.extendedProps.sun_value = "";
        this.eventLocal.extendedProps.mon_value = "";
        this.eventLocal.extendedProps.tue_value = "";
        this.eventLocal.extendedProps.wed_value = "";
        this.eventLocal.extendedProps.thu_value = "";
        this.eventLocal.extendedProps.fri_value = "";
      }
    },
    onSameTractor(id) {
      if (id === "sametractor") {
        this.sat_tractor_id = this.eventLocal.extendedProps.sat_tractor_id;
        this.same_tractor_selected = true;

        this.eventLocal.extendedProps.sun_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
        this.eventLocal.extendedProps.mon_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
        this.eventLocal.extendedProps.tue_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
        this.eventLocal.extendedProps.wed_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
        this.eventLocal.extendedProps.thu_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
        this.eventLocal.extendedProps.fri_tractor_id =
          this.eventLocal.extendedProps.sat_tractor_id.title;
      } else {
        this.same_tractor_selected = false;

        this.eventLocal.extendedProps.sun_tractor_id = "";
        this.eventLocal.extendedProps.mon_tractor_id = "";
        this.eventLocal.extendedProps.tue_tractor_id = "";
        this.eventLocal.extendedProps.wed_tractor_id = "";
        this.eventLocal.extendedProps.thu_tractor_id = "";
        this.eventLocal.extendedProps.fri_tractor_id = "";
      }
    },
    addRoute() {
      this.routeName = "";
      this.originName = "";
      this.destinationName = "";
      this.distance = "";
      this.$refs["modal-add-route"].show();
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },

    updateRoute() {
      if (this.eventLocal.id != "") {
        this.routeId = this.eventLocal.extendedProps.route.id;
        this.routeName = this.eventLocal.extendedProps.route.name;
        this.distance = this.eventLocal.extendedProps.route.distance;
        this.originName = this.eventLocal.extendedProps.route.origin;
        this.destinationName = this.eventLocal.extendedProps.route.distance;
      }

      this.$refs["modal-add-route"].show();
    },

    addRouteData() {
      if (this.routeName == "") {
        this.showToast("warning", "warning", "Please fill the route name.");
      } else if (this.originName == "") {
        this.showToast("warning", "warning", "Please fill the origin name.");
      } else if (this.destinationName == "") {
        this.showToast(
          "warning",
          "warning",
          "Please fill the destination name."
        );
      } else if (this.distance == "") {
        this.showToast("warning", "warning", "Please fill the distance.");
      } else {
        axios
          .post(`${serverUri}/drivers/addRouteByDomicile`, {
            domicile_id: this.eventLocal.extendedProps.domicile.name,
            route: this.routeName,
            origin: this.originName,
            destination: this.destinationName,
            distance: this.distance,
            status: 1,
          })
          .then((res) => {
            if (res.data.status) {
              this.routeName = "";
              this.originName = "";
              this.destinationName = "";
              this.distance = "";

              this.showToast("success", "success", res.data.message);

              this.$refs["modal-add-route"].hide();

              this.routes = [];
              axios
                .get(
                  `${serverUri}/drivers/allRoutes/${this.eventLocal.extendedProps.domicile.name}`
                )
                .then((res) => {
                  res.data.map((item) => {
                    this.routes.push({
                      title: item.id,
                      name: item.route,
                      id: item.id,
                      origin: item.origin,
                      destination: item.destination,
                      distance: item.distance,
                    });
                  });
                });
            } else {
              this.showToast("warning", "warning", res.data.message);
            }
          });
      }
    },

    updateRouteData() {
      if (this.routeName == "") {
        this.showToast("warning", "warning", "Please fill the route name.");
      } else if (this.originName == "") {
        this.showToast("warning", "warning", "Please fill the origin name.");
      } else if (this.destinationName == "") {
        this.showToast(
          "warning",
          "warning",
          "Please fill the destination name."
        );
      } else if (this.distance == "") {
        this.showToast("warning", "warning", "Please fill the distance.");
      } else {
        axios
          .post(`${serverUri}/drivers/UpdateRouteByDomicile`, {
            route_id: this.routeId,
            domicile_id: this.eventLocal.extendedProps.domicile.name,
            route: this.routeName,
            origin: this.originName,
            destination: this.destinationName,
            distance: this.distance,
          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "success",
                "Route has been updated successfully."
              );

              this.$refs["modal-add-route"].hide();

              this.routes = [];
              axios
                .get(
                  `${serverUri}/drivers/allRoutes/${this.eventLocal.extendedProps.domicile.name}`
                )
                .then((res) => {
                  res.data.map((item) => {
                    this.routes.push({
                      title: item.id,
                      name: item.route,
                      id: item.id,
                      origin: item.origin,
                      destination: item.destination,
                      distance: item.distance,
                    });
                  });
                });

              this.eventLocal.extendedProps.eventDestinationName =
                this.destinationName;
              this.eventLocal.extendedProps.eventOriginName = this.originName;
              this.eventLocal.extendedProps.eventDistance = this.distance;
              this.eventOriginName = this.originName;
              this.eventLocal.extendedProps.route = {
                title: this.routeId,
                name: this.routeName,
                id: this.routeId,
                origin: this.originName,
                destination: this.destinationName,
                distance: this.distance,
              };

              this.RouteSelect();
            }
          });
      }
    },

    RouteSelect() {
      this.eventOriginName = this.eventLocal.extendedProps.route.origin;
      this.eventLocal.extendedProps.eventDestinationName =
        this.eventLocal.extendedProps.route.destination;
      this.eventLocal.extendedProps.eventOriginName =
        this.eventLocal.extendedProps.route.origin;
      this.eventLocal.extendedProps.eventDistance =
        this.eventLocal.extendedProps.route.distance;

      this.routeId = this.eventLocal.extendedProps.route.id;
      this.routeName = this.eventLocal.extendedProps.route.name;
      this.distance = this.eventLocal.extendedProps.route.distance;
      this.originName = this.eventLocal.extendedProps.route.origin;
      this.destinationName = this.eventLocal.extendedProps.route.distance;
    },
    DomicileSelect() {
      this.drivers = [];
      this.routes = [];
      this.vehicleOption = [];
      this.tractorsOption = [];
      this.eventLocal.driver = "";
      this.eventLocal.tractor = "";
      this.eventLocal.route = "";
      this.selectDomicile = this.eventLocal.extendedProps.domicile.name;

      // get drivers
      axios
        .get(
          `${serverUri}/drivers/alldriversdataByDomicile/${
            getUserData().company_id
          }/${this.eventLocal.extendedProps.domicile.name}`
        )
        .then((res) => {
          res.data.map((item) => {
            this.drivers.push({
              avatar: item.photo,
              name: item.driver_name,
              id: item.id,
            });
          });
        });

      // get routes
      axios
        .get(
          `${serverUri}/drivers/allRoutes/${this.eventLocal.extendedProps.domicile.name}`
        )
        .then((res) => {
          res.data.map((item) => {
            // this.routes.push({ title: item.id, name: item.route, id: item.id });

            this.routes.push({
              title: item.id,
              name: item.route,
              id: item.id,
              origin: item.origin,
              destination: item.destination,
              distance: item.distance,
            });
          });
        });

      // get vehicles
      axios
        .post(`${serverUri}/fleet/getFleetByDomicile`, {
          company: getUserData().company_id,
          status: "Active",
          domicile: this.eventLocal.extendedProps.domicile.name,
        })
        .then((res) => {
          // this.tractorsOption.push({ title: 'OFF' })
          res.data.map((item) => {
            this.tractorsOption.push({
              title: item.tractor_id,
            });
            this.vehicleOption.push({
              title: item.id,
              name: item.tractor_id + " (" + item.model + "-" + item.year + ")",
              id: item.id,
            });
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.b-sidebar.sidebar-lg {
  width: 50rem;
}
.fc-event-time {
  display: none !important;
}
label[for="least_time"] {
  display: none !important;
}
label[for="least_tractor"] {
  display: none !important;
}
.edit_icon {
  float: right;
  color: #514ca0;
  cursor: pointer;
}
.delete_icon {
  color: red;
  cursor: pointer;
}
.time_icon {
    position: absolute;
    right: 30px;
    top: 35px;
    // width:5%;
    // float:left;

}
// .schedule_time_pick {
//   width:85%;
//   float:left;
// }
</style>
