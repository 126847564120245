<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true),resetForm()"
      >
        Add Schedule
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="openCopyScheduleModel()"
        >Copy Schedule
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="openSendScheduleModel()"
        >Send/Export Schedule
      </b-button>

      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Filters</span>
        </h5>

        <b-form-group label="Domicile" label-for="domicile">
          <v-select
            label="label"
            placeholder="Select"
            v-model="selectedDomicile"
            :options="domiciles"
            @input="DomicileSelect()"
          />
        </b-form-group>

        <b-form-group label="Route" label-for="route">
          <span
            style="cursor: pointer; color: #7367f0"
            @click="addRoute"
            v-if="selectedDomicile.title != ''"
            >+ New Route</span
          >
          <v-select
            label="label"
            placeholder="Select"
            v-model="selectedRoute"
            :options="routes"
            @input="RouteSelect()"
          />
        </b-form-group>

        <b-form-group label="Vehicle" label-for="vehicle">
          <v-select
            v-model="vehicle"
            label="label"
            :options="vehicleOption"
            placeholder="Select"
          />
        </b-form-group>

        <b-form-group label="Driver" label-for="driver">
          <v-select
            label="label"
            placeholder="Select"
            v-model="selectedDriver"
            :options="drivers"
            @input="DriverSelect()"
          />
        </b-form-group>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          style="margin-right: 10px"
          @click="FilterData"
          >Filter
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          @click="ResetData"
          >Reset
        </b-button>

        <!-- <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          View All
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group> -->
      </div>
    </div>
    <!--<b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />-->

    <b-modal
      size="lg"
      id="modal-add-route"
      ref="modal-add-route"
      :hide-footer="true"
      title="Add Route"
    >
      <b-row class="match-height">
        <b-col cols="12">
          <b-form-group
            label="Route"
            label-for="h-route-name"
            label-cols-md="4"
          >
            <b-form-input id="h-route-name" v-model="routeName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Origin"
            label-for="h-origin-name"
            label-cols-md="4"
          >
            <b-form-input id="h-origin-name" v-model="originName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Destination"
            label-for="h-destination-name"
            label-cols-md="4"
          >
            <b-form-input id="h-destination-name" v-model="destinationName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Distance"
            label-for="h-distance"
            label-cols-md="4"
          >
            <b-form-input id="h-distance-name" v-model="distance" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button @click="addRouteData">Submit </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-copy-schedule"
      ref="modal-copy-schedule"
      :hide-footer="true"
      title="Copy Next Week Schedule"
    >
      <b-row class="match-height">
        <b-col cols="12">
          <b-form-group label="Driver" label-for="driver">
            <v-select
              multiple
              v-model="selectedScheduleDrivers"
              :close-on-select="false"
              :options="scheduleDrivers"
              label="name"
              placeholder="All Drivers"
              :clearable="false"
              input-id="driver"
              @input="changeDriver"
            />

            <!-- :selectable="() => selectedScheduleDrivers.id != 'all'" -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button :disabled="ApplyDisabled" @click="copyScheduleData"
            >Apply
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-send-schedule"
      ref="modal-send-schedule"
      :hide-footer="true"
      title="Send Schedule"
    >
      <b-row class="match-height">
        <b-col cols="12">
          <b-form-group label="Type" label-for="type">
            <v-select
              v-model="type"
              :close-on-select="true"
              :options="typeOptions"
              label="label"
              placeholder="Select.."
              :clearable="false"
              input-id="type"
              @input="changeType"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="type.title == 'driver'">
          <b-form-group label="Driver" label-for="driver">
            <v-select
              multiple
              v-model="selectedScheduleDrivers"
              :close-on-select="false"
              :options="scheduleDrivers"
              label="name"
              placeholder="All Drivers"
              :clearable="false"
              input-id="driver"
              @input="changeDriver"
            />
          </b-form-group>
        </b-col>

        <b-form-radio-group
          id="export-options"
          v-model="exportOption"
          stacked
          v-if="clickedVia"
        >
          <!-- :options="exportOptions" -->
          <b-form-radio
            v-for="option in exportOptions"
            :key="option.value"
            :value="option.value"
            :class="`custom-control-${option.radioVariant}`"
          >
            {{ option.text }}
          </b-form-radio>
        </b-form-radio-group>

        <b-col cols="12" v-if="type.title == 'domicile'">
          <b-form-group label="Domicile" label-for="domicile">
            <v-select
              multiple
              v-model="selectedScheduleDomiciles"
              :close-on-select="false"
              :options="scheduleDomiciles"
              label="name"
              placeholder="All Domiciles"
              :clearable="false"
              input-id="driver"
              @input="changeDomicile"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="SendScheduleDisabled"
            @click="sendScheduleData"
            >Send
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            :disabled="ExportScheduleDisabled"
            @click="exportScheduleData"
            ><feather-icon icon="DownloadIcon" class="mr-50" /> Export
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BFormInput,
  BRow,
  BCol,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useCalendarSidebar from "./useCalendarSidebar";
import vSelect from "vue-select";
import axios from "axios";
import { getUserData } from "@/auth/utils";
import { serverUri } from "@/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { mapGetters, mapActions } from "vuex";
import Vue from 'vue';

window.eventBus = new Vue();

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    // BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    // BFormCheckboxGroup,
    vSelect,
    BFormInput,
  },
  data() {
    return {
      clickedVia: false,
      selectedDomicile: "",
      domiciles: [],
      selectedRoute: "",
      routes: [],
      selectedDriver: "",
      selectedScheduleDrivers: [],
      selectedScheduleDomiciles: [],
      scheduleDomiciles: [],
      drivers: [],
      scheduleDrivers: [],
      currentWeekSchedule: [],
      routeName: "",
      originName: "",
      destinationName: "",
      distance: "",
      vehicle: "",
      vehicleOption: [],
      weekStart : '',
      weekEnd : '',
      weekSchedules : [],
      typeOptions: [
        { title: "driver", label: "Driver" },
        { title: "domicile", label: "Domicile" },
      ],
      exportOption: "",
      exportOptions: [
        { text: "PDF", value: "pdf", radioVariant: "success" },
        { text: "Excel", value: "excel", radioVariant: "danger" },
      ],
      type: "",
      driversSelectedIds: [],
      getUserData: getUserData(),
      ApplyDisabled: false,
      SendScheduleDisabled: false,
      ExportScheduleDisabled: false,
    };
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    domicileFilter: {
      type: [String, null],
      default: null,
    },
    routeFilter: {
      type: [String, null],
      default: null,
    },
    vehicleFilter: {
      type: [String, null],
      default: null,
    },
    driverFilter: {
      type: [String, null],
      default: null,
    },
  },
  setup() {
    const { calendarOptions, selectedCalendars, checkAll } =
      useCalendarSidebar();

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    };
  },
  beforeDestroy() {
    EventBus.$off("getSelectedWeekDetails");
    EventBus.$off("getCurrentWeekSchedules");    
  },
  created() {

    EventBus.$on("getSelectedWeekDetails", (week) => {
      this.weekStart = week.start;
      this.weekEnd = week.end;
    });

    EventBus.$on("getCurrentWeekSchedules", (schedules) => {
      this.weekSchedules = schedules.data;
    });

    // localStorage.setItem('domicile',this.selectedDomicile);
    //   localStorage.setItem('vehicle',this.vehicle);
    //   localStorage.setItem('route',this.selectedRoute);
    //   localStorage.setItem('driver',this.selectedDriver);

    // get domiciles
    axios
      .post(`${serverUri}/schedule/getdomicile`, {
        company: getUserData().company_id,
      })
      .then((res) => {
        const Response = res.data.filter((item) => item.domicile?.length > 0);

        Response.map((item) => {
          this.domiciles.push({ title: item.domicile, label: item.domicile });
        });
      });

    if (localStorage.getItem("domicile") != "undefined" && localStorage.getItem("domicile") != undefined) {
      this.selectedDomicile = JSON.parse(localStorage.getItem("domicile"));
      this.DomicileSelect();
    }

  },
  methods: {

    resetForm()
    {
      EventBus.$emit('resetFormData', {type:'add_all'});
    },

    changeType() {
      this.selectedScheduleDomiciles = [];
      this.selectedScheduleDrivers = [];
      this.clickedVia = true;
      this.exportOption = "";
    },
    changeDomicile() {},
    changeDriver() {
      this.driversSelectedIds = [];
      this.selectedScheduleDrivers.map((item) => {
        this.driversSelectedIds.push(item.id);
      });
    },
    openCopyScheduleModel() {
      this.ApplyDisabled = false;
      this.getAutomaticScheduleData();

      this.$refs["modal-copy-schedule"].show();
    },

    getAutomaticScheduleData() {
      this.selectedScheduleDrivers = [];

      var startWeek = moment().subtract(0, "weeks").startOf("isoWeek");
      var endWeek = moment().subtract(0, "weeks").endOf("isoWeek");

      var s = new Date(startWeek);
      var e = new Date(endWeek);

      if(this.weekStart == '' && this.weekEnd == '')
      {
          var StartDay =
          moment(s.setDate(s.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";
        var EndDay =
          moment(e.setDate(e.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";

      } else {

        var StartDay = this.weekStart;
        var EndDay   = this.weekEnd;
      }

      const currentScheduleData = this.weekSchedules;

      // const currentScheduleData = JSON.parse(
      //     localStorage.getItem("currentSchedule")
      //   );    

      this.scheduleDrivers = [];
      this.scheduleDomiciles = [];
      // this.scheduleDrivers.push({ id: "all", avatar: "", name: "All" });
      const tempArr = [];
      this.currentWeekSchedule = [];
      if (currentScheduleData.length == 0) {
        this.showToast(
          "warning",
          "warning",
          "Please wait for until get the schedules"
        );
        return false;
      }
      currentScheduleData.map((item) => {
        if (
          moment(item.start) >= moment(StartDay) &&
          moment(item.start) <= moment(EndDay)
        ) {
          this.currentWeekSchedule.push(item);
          this.scheduleDrivers.push(item.driver);
          this.scheduleDomiciles.push(item.domicile);
        }
      });

      const uniqueDrivers = Array.from(
        new Set(this.scheduleDrivers.map((item) => item.id))
      ).map((id) => this.scheduleDrivers.find((item) => item.id === id));
      this.scheduleDrivers = uniqueDrivers;

      const uniqueDomiciles = Array.from(
        new Set(this.scheduleDomiciles.map((item) => item.title))
      ).map((id) => this.scheduleDomiciles.find((item) => item.title === id));
      this.scheduleDomiciles = uniqueDomiciles;
    },

    openSendScheduleModel() {
      this.type = "";
      this.clickedVia = false;

      this.getAutomaticScheduleData();
      this.$refs["modal-send-schedule"].show();
    },

    copyScheduleData() {
      var startNWeek = moment().add(1, "weeks").startOf("isoWeek");
      var endNWeek = moment().add(1, "weeks").endOf("isoWeek");

      var s = new Date(startNWeek);
      var e = new Date(endNWeek);

      var StartDay =
        moment(s.setDate(s.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";
      var EndDay =
        moment(e.setDate(e.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";

      var filterScheduleData = [];

      if (this.selectedScheduleDrivers.length == 0) {

        this.currentWeekSchedule.map((item) => {
            item.start = this.getNextWeekDay(item.start,StartDay,EndDay);
            filterScheduleData.push(item);
        });

      } else {
        this.currentWeekSchedule.map((item) => {
          if (this.driversSelectedIds.includes(item.driver.id)) {
            item.start = this.getNextWeekDay(item.start,StartDay,EndDay);
            filterScheduleData.push(item);
          }
        });
      }

      var currentDay =  moment().format("YYYY-MM-DD");;

      if(StartDay >= currentDay && EndDay <= currentDay)
      {
        var type = 'current';

      } else {
        var type = 'previous';

      }


      this.ApplyDisabled = true;
      this.$swal({
        title: "Are you sure to copy current week schedule to next week?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        axios
          .post(`${serverUri}/drivers/copyScheduleData`, {
            company: getUserData().company_id,
            timings: filterScheduleData,
            type : type
          })
          .then((res) => {
            if (res.data.status) {
              this.ApplyDisabled = false;

              this.showToast(
                "success",
                "success",
                "All current week data copied to next week."
              );
              this.$refs["modal-copy-schedule"].hide();
              this.selectedScheduleDrivers = [];
            } else {
              this.ApplyDisabled = false;

              this.showToast("warning", "warning", res.data.message);
              this.$refs["modal-copy-schedule"].hide();
              this.selectedScheduleDrivers = [];
            }
          });
      });
    },

    sendScheduleData() {
      this.exportOptions = [];

      this.exportOptions.push({
        text: "PDF",
        value: "pdf",
        radioVariant: "success",
      });

      if (!this.type) {
        this.showToast("warning", "warning", "Please select type.");
        return false;
      }

      if (!this.exportOption) {
        this.showToast("warning", "warning", "Please select file format.");
        return false;
      }

      // send schedule data
      this.getScheduleData("send_mail", "pdf");
    },

    exportScheduleData() {
      this.exportOptions = [];

      this.exportOptions.push(
        { text: "PDF", value: "pdf", radioVariant: "success" },
        { text: "Excel", value: "excel", radioVariant: "danger" }
      );

      if (!this.type) {
        this.showToast("warning", "warning", "Please select type.");
        return false;
      }

      if (!this.exportOption) {
        this.showToast("warning", "warning", "Please select export format.");
        return false;
      }

      // export data
      this.getScheduleData("export_data", this.exportOption.value);
    },

    getScheduleData(method, format) {
      var startNWeek = moment().add(1, "weeks").startOf("isoWeek");
      var endNWeek = moment().add(1, "weeks").endOf("isoWeek");

      var s = new Date(startNWeek);
      var e = new Date(endNWeek);

      var StartDay =
        moment(s.setDate(s.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";
      var EndDay =
        moment(e.setDate(e.getDate() - 2)).format("YYYY-MM-DD") + " 00:00:00";

      var filterScheduleData = [];
      var filterDriversData = [];
      var filterDomicilesData = [];

      if (this.type.title == "driver") {
        if (this.selectedScheduleDrivers.length == 0) {
          filterScheduleData = this.currentWeekSchedule;
          filterDomicilesData = [];
          filterDriversData = this.scheduleDrivers.map((item) => {
            return item.id;
          });
        } else {
          this.currentWeekSchedule.map((item) => {
            if (this.driversSelectedIds.includes(item.driver.id)) {
              filterScheduleData.push(item);
            }
          });

          filterDomicilesData = [];
          filterDriversData = this.selectedScheduleDrivers.map((item) => {
            return item.id;
          });
        }
      } else {
        if (this.selectedScheduleDomiciles.length == 0) {
          filterScheduleData = this.currentWeekSchedule;

          filterDomicilesData = this.scheduleDomiciles.map((item) => {
            return item.title;
          });
          filterDriversData = [];
        } else {

          this.currentWeekSchedule.map((item) => {
            if (this.selectedScheduleDomiciles.includes(item.domicile.title)) {
              filterScheduleData.push(item);
            }
          });

          filterDomicilesData = this.selectedScheduleDomiciles.map((item) => {
            return item.title;
          });
          filterDriversData = [];
        }
      }

      if (method == "send_mail") {
        this.SendScheduleDisabled = true;
      } else {
        this.ExportScheduleDisabled = true;
      }

      if (this.type.title != "driver") {
        axios
          .post(`${serverUri}/drivers/sendScheduleData`, {
            method: method,
            format: format,
            company_id: getUserData().company_id,
            type: this.type,
            domicile: this.type.title != "driver" ? filterDomicilesData : [],
            driver: this.type.title == "driver" ? filterDriversData : "",
            timings: filterScheduleData,
          })
          .then((res) => {
            if (res.data.status) {
              if (method == "send_mail") {
                this.SendScheduleDisabled = false;
              } else {
                this.ExportScheduleDisabled = false;
              }

              this.showToast("success", "success", res.data.message);
              this.$refs["modal-send-schedule"].hide();
              this.selectedScheduleDrivers = [];

              if (format == "pdf") {
                this.pdfView = res.data.pdf;

                let pdfWindow = window.open("pdf_view");
                pdfWindow.document.write(
                  "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(this.pdfView) +
                    "'></iframe>"
                );
              } else {
                const ExportExcelData = [];

                filterScheduleData.map((week_data) => {
                  ExportExcelData.push({
                    "Driver Name": week_data.driver.name,
                    Day: moment(week_data.start).format("MM/DD/YYYY"),
                    Tractor: week_data.vehicle.title,
                    "Dispatch Time": week_data.time,
                    Route: week_data.route.name,
                  });
                });

                const data = ExportExcelData;
                const fileName = "Schedule";
                const exportType = exportFromJSON.types.csv;

                if (data) exportFromJSON({ data, fileName, exportType });
              }
            } else {
              if (method == "send_mail") {
                this.SendScheduleDisabled = false;
              } else {
                this.ExportScheduleDisabled = false;
              }

              this.showToast("warning", "warning", res.data.message);
              this.$refs["modal-send-schedule"].hide();
              this.selectedScheduleDrivers = [];
            }
          });
      } else {
        for (var i = 0; i < filterDriversData.length; i++) {
          const filterScheduleDriversData = [];

          filterScheduleData.map((item) => {
            if (filterDriversData[i] == item.driver.id) {
              filterScheduleDriversData.push(item);
            }
          });

          axios
            .post(`${serverUri}/drivers/sendScheduleData`, {
              method: method,
              format: format,
              company_id: getUserData().company_id,
              type: this.type,
              domicile: this.type.title != "driver" ? filterDomicilesData : [],
              driver: this.type.title == "driver" ? filterDriversData[i] : "",
              timings: filterScheduleDriversData,
            })
            .then((res) => {
              if (res.data.status) {
                if (method == "send_mail") {
                  this.SendScheduleDisabled = false;
                } else {
                  this.ExportScheduleDisabled = false;
                }

                this.showToast("success", "success", res.data.message);
                this.$refs["modal-send-schedule"].hide();
                this.selectedScheduleDrivers = [];

                if (method == "send_mail") {
                  this.SendScheduleDisabled = false;
                } else {
                  this.ExportScheduleDisabled = false;

                  if (format == "pdf") {
                    this.pdfView = res.data.pdf;

                    let pdfWindow = window.open("pdf_view");
                    pdfWindow.document.write(
                      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                        encodeURI(this.pdfView) +
                        "'></iframe>"
                    );
                  } else {
                    const ExportExcelData = [];

                    filterScheduleData.map((week_data) => {
                      ExportExcelData.push({
                        Day: moment(week_data.start).format("MM/DD/YYYY"),
                        Tractor: week_data.vehicle.title,
                        "Dispatch Time": week_data.time,
                        Route: week_data.route.name,
                      });
                    });

                    const data = ExportExcelData;
                    const fileName = "Schedule";
                    const exportType = exportFromJSON.types.csv;

                    if (data) exportFromJSON({ data, fileName, exportType });
                  }
                }
              } else {
                if (method == "send_mail") {
                  this.SendScheduleDisabled = false;
                } else {
                  this.ExportScheduleDisabled = false;
                }

                this.showToast("warning", "warning", res.data.message);
                this.$refs["modal-send-schedule"].hide();
                this.selectedScheduleDrivers = [];
              }
            });
        }
      }
    },

    getNextWeekDay(start,nextWeekStart,nextWeekEnd) {
      console.log(nextWeekStart);
      // console.log(nextWeekEnd);

      var getDay = moment(new Date(start)).format("ddd");

      console.log(getDay);

      var getDayValue = {};

      if(getDay == 'Mon')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate() + 2)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Tue')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate() + 3)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Wed')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate() + 4)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Thu')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate() + 5)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Fri')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate() + 6)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Sat')
      {
        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate())).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      } else if (getDay == 'Sun') {

        var s             = new Date(nextWeekStart);
        var startFrom     =  moment(s.setDate(s.getDate()+1)).format(
          "YYYY-MM-DD"
        ) + " 00:00:00"; 

      }
      console.log(startFrom);

      // var nextWeekDay = new Date(startFrom);
      // var DayDetails =
      //   moment(nextWeekDay.setDate(nextWeekDay.getDate())).format(
      //     "YYYY-MM-DD"
      //   ) + " 00:00:00";

      return startFrom;
    },
    DomicileSelect() {
      // this.ApplyDisabled = false;

      // get drivers
      this.drivers = [];

      var domicile = '';

      if(this.selectedDomicile == null)
      {
         domicile = null;
      } else {
         domicile = this.selectedDomicile.title;
      }
      axios
        .get(
          `${serverUri}/drivers/alldriversdataByDomicile/${
            getUserData().company_id
          }/${domicile}`
        )
        .then((res) => {
          res.data.map((item) => {
            this.drivers.push({ title: item.id, label: item.driver_name });
          });

        
        });

      // get routes
      this.routes = [];
      axios
        .get(`${serverUri}/drivers/allRoutes/${domicile}`)
        .then((res) => {
          res.data.map((item) => {
            this.routes.push({ title: item.id, label: item.route });
          });

         
        });

      // get vehicles
      this.vehicleOption = [];
      axios
        .post(`${serverUri}/fleet/getFleetByDomicile`, {
          company: getUserData().company_id,
          status: "Active",
          domicile: domicile,
        })
        .then((res) => {
          res.data.map((item) => {
            this.vehicleOption.push({
              title: item.id,
              label:
                item.tractor_id + " (" + item.model + "-" + item.year + ")",
            });
          });
          
        });

        

        if (localStorage.getItem("driver") != "undefined"  && localStorage.getItem("driver") != undefined) {
            this.selectedDriver = JSON.parse(localStorage.getItem("driver"));
          }

          if (localStorage.getItem("vehicle") != "undefined"   && localStorage.getItem("vehicle") != undefined) {
            this.selectedRoute = JSON.parse(localStorage.getItem("vehicle"));
          }

          if (localStorage.getItem("route") != "undefined"    && localStorage.getItem("route") != undefined) {
            this.selectedRoute = JSON.parse(localStorage.getItem("route"));
          }
    },

    RouteSelect() {},

    addRoute() {
      this.$refs["modal-add-route"].show();
    },

    DriverSelect() {},

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },

    addRouteData() {
      if (this.routeName == "") {
        this.showToast("warning", "warning", "Please fill the route name.");
      } else if (this.originName == "") {
        this.showToast("warning", "warning", "Please fill the origin name.");
      } else if (this.destinationName == "") {
        this.showToast(
          "warning",
          "warning",
          "Please fill the destination name."
        );
      } else if (this.distance == "") {
        this.showToast("warning", "warning", "Please fill the distance.");
      } else {
        axios
          .post(`${serverUri}/drivers/addRouteByDomicile`, {
            domicile_id: this.selectedDomicile.title,
            route: this.routeName,
            origin: this.originName,
            destination: this.destinationName,
            distance: this.distance,
            status: 1,
          })
          .then((res) => {
            if (res.data.status) {
              this.routeName = "";
              this.originName = "";
              this.destinationName = "";
              this.distance = "";

              this.showToast(
                "success",
                "success",
                res.data.message
              );

              this.$refs["modal-add-route"].hide();

              this.routes = [];
              axios
                .get(
                  `${serverUri}/drivers/allRoutes/${this.selectedDomicile.title}`
                )
                .then((res) => {
                  res.data.map((item) => {
                    this.routes.push({ title: item.id, label: item.route });
                  });
                });
            } else {

              this.showToast(
                "warning",
                "warning",
                res.data.message
              );

            }
          });
      }
    },

    FilterData() {
      if (this.selectedDomicile == "" || this.selectedDomicile == null || this.selectedDomicile == undefined) {
        this.showToast("warning", "warning", "Please select Domicile");
        return false;
      }

      localStorage.setItem("domicile", JSON.stringify(this.selectedDomicile));

      if (this.vehicle != null && this.selectedDriver != "") {
        localStorage.setItem("vehicle", JSON.stringify(this.vehicle));
      } else {
        localStorage.setItem("vehicle", null);
      }

      if (this.selectedRoute != null && this.selectedDriver != "") {
        localStorage.setItem("route", JSON.stringify(this.selectedRoute));
      } else {
        localStorage.setItem("route", null);
      }

      if (this.selectedDriver != null && this.selectedDriver != "") {
        localStorage.setItem("driver", JSON.stringify(this.selectedDriver));
      } else {
        localStorage.setItem("driver", null);
      }

      this.$router.go()

      // console.log(this.$store.dispatch('fetchEvents', null, {root:true}));
    },

    ResetData() {
      localStorage.removeItem("domicile");
      localStorage.removeItem("vehicle");
      localStorage.removeItem("route");
      localStorage.removeItem("driver");
      this.$router.go()

    },
  },
};
</script>

<style>
div#export-options {
  display: flex;
  margin: 10px 10px;
}
</style>
