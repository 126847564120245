// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import moment from "moment";
// moment.tz.setDefault('America/New_York')
import { getUserData } from "@/auth/utils";
import Vue from 'vue';

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
window.eventBus = new Vue();


export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',

    extendedProps: {
      dayClick: false,
      domicile: '',
      route: '',
      tractor: '',
      driver: '',
      vehicle: '',
      time: '',
      eventOriginName: '',
      eventDestinationName: '',
      eventDistance: '',
      sameTime :'',
      timings: [
        {
          day: '',
          tractor_id: ''
        },
      ]

    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {

    console.log(updatedEventData);

    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Schedule Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()

    refetchEvents();

  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        dayClick,
        domicile,
        route,
        driver,
        vehicle,
        time,
        eventOriginName,
        eventDestinationName,
        eventDistance,
        sameTime,
        timings
      },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      extendedProps: {
        dayClick,
        domicile,
        route,
        driver,
        vehicle,
        time,
        eventOriginName,
        eventDestinationName,
        eventDistance,
        sameTime,
        timings
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {

    // console.log(eventData.start);

    // if (eventData.start == '') {

    //   toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: 'Please select date',
    //       icon: 'AlertTriangleIcon',
    //       variant: 'warning',
    //     },
    //   })
    //   return false;
    // }
 

    if (eventData.extendedProps.domicile == '') {

      toast({
        component: ToastificationContent,
        props: {
          title: 'Please select domicile',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
      return false;
    }

    if (eventData.extendedProps.route == '') {

      toast({
        component: ToastificationContent,
        props: {
          title: 'Please select route',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
      return false;
    }

    if (eventData.extendedProps.driver == '') {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Please select driver',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
      return false;
    }

    // if (
    //   eventData.extendedProps.sat_tractor_id != "OFF" ||
    //   eventData.extendedProps.fri_tractor_id != "OFF" ||
    //   eventData.extendedProps.thu_tractor_id != "OFF" ||
    //   eventData.extendedProps.thu_tractor_id != "OFF" ||
    //   eventData.extendedProps.wed_tractor_id != "OFF" ||
    //   eventData.extendedProps.tue_tractor_id != "OFF" ||
    //   eventData.extendedProps.mon_tractor_id != "OFF"
    // ) {
    //   toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: 'Please select tractor',
    //       icon: 'AlertTriangleIcon',
    //       variant: 'warning',
    //     },
    //   })
    //   return false;
    // }

    // if (
    //   eventData.extendedProps.sat_value != "" ||
    //   eventData.extendedProps.fri_value != null ||
    //   eventData.extendedProps.thu_value != null ||
    //   eventData.extendedProps.thu_value != null ||
    //   eventData.extendedProps.wed_value != null ||
    //   eventData.extendedProps.tue_value != null ||
    //   eventData.extendedProps.mon_value != null
    // ) {
    //   toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: 'Please select time',
    //       icon: 'AlertTriangleIcon',
    //       variant: 'warning',
    //     },
    //   })
    //   return false;
    // }



    var startWeek = moment().subtract(0, "weeks").startOf("isoWeek");
    var endWeek = moment().subtract(0, "weeks").endOf("isoWeek");

    if(localStorage.getItem('start_week') == undefined)
    {
      var s = new Date(startWeek);
      var e = new Date(endWeek);


      var StartDay = moment(s.setDate(s.getDate() - 2)).format("YYYY-MM-DD");
        // var EndDay = moment(e.setDate(e.getDate() - 2)).format("YYYY-MM-DD");

        if (eventData.start) {
          var curr = new Date(eventData.start);

        } else {

          var curr = new Date();

        }

      
      var CurrentDay = moment(curr.setDate(curr.getDate() + 1)).format("YYYY-MM-DD");

      var now = new Date(CurrentDay);
      var SessionDay = localStorage.getItem('start_day') == undefined ? 6 : localStorage.getItem('start_day');
      var daysAfterLastSaturday = (-7 + 6) - now.getDay(); // 7 = number of days in week, 6 = the saturdayIndex (0= sunday)
      var currentMs = now.getTime();
      var lastWeekSaturday = new Date(currentMs + (daysAfterLastSaturday * 24 * 60 * 60 * 1000));

      var LastSaturday = moment(lastWeekSaturday).format('YYYY-MM-DD');

      var NewDay = new Date(LastSaturday);


      } else {


        var s = new Date(localStorage.getItem('start_week'));
        var e = new Date(localStorage.getItem('end_week'));


        var StartDay = moment(s.setDate(s.getDate())).format("YYYY-MM-DD");

          // var EndDay = moment(e.setDate(e.getDate() - 2)).format("YYYY-MM-DD");
          
          var curr = new Date(StartDay);

          var LastSaturday = StartDay;

          var NewDay = new Date(LastSaturday);

      }



    if (moment(LastSaturday) < moment(StartDay)) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Sorry! You are choosing the old week.',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      })
      return false;
    }

    var satTractor = eventData.extendedProps.sat_tractor_id == undefined ? '' : eventData.extendedProps.sat_tractor_id.title;
    var sunTractor = eventData.extendedProps.sun_tractor_id == undefined ? '' : eventData.extendedProps.sun_tractor_id.title;
    var monTractor = eventData.extendedProps.mon_tractor_id == undefined ? '' : eventData.extendedProps.mon_tractor_id.title;
    var tueTractor = eventData.extendedProps.tue_tractor_id == undefined ? '' : eventData.extendedProps.tue_tractor_id.title;
    var wedTractor = eventData.extendedProps.wed_tractor_id == undefined ? '' : eventData.extendedProps.wed_tractor_id.title;
    var thuTractor = eventData.extendedProps.thu_tractor_id == undefined ? '' : eventData.extendedProps.thu_tractor_id.title;
    var friTractor = eventData.extendedProps.fri_tractor_id == undefined ? '' : eventData.extendedProps.fri_tractor_id.title;


    var satValue = eventData.extendedProps.sat_value == undefined ? '00:00:00' : eventData.extendedProps.sat_value;
    var sunValue = eventData.extendedProps.sun_value == undefined ? '00:00:00' : eventData.extendedProps.sun_value;
    var monValue = eventData.extendedProps.mon_value == undefined ? '00:00:00' : eventData.extendedProps.mon_value;
    var tueValue = eventData.extendedProps.tue_value == undefined ? '00:00:00' : eventData.extendedProps.tue_value;
    var wedValue = eventData.extendedProps.wed_value == undefined ? '00:00:00' : eventData.extendedProps.wed_value;
    var thuValue = eventData.extendedProps.thu_value == undefined ? '00:00:00' : eventData.extendedProps.thu_value;
    var friValue = eventData.extendedProps.fri_value == undefined ? '00:00:00' : eventData.extendedProps.fri_value;


    var Timings = [
      {
        day: LastSaturday + " " + satValue,
        time:satValue,
        tractor_id: satTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          sunValue,
          time:sunValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : sunTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          monValue,
          time:monValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : monTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          tueValue,
          time:tueValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : tueTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          wedValue,
          time:wedValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : wedTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          thuValue,
          time:thuValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : thuTractor,
      },
      {
        day:
          moment(NewDay.setDate(NewDay.getDate() + 1)).format("YYYY-MM-DD") +
          " " +
          friValue,
          time:friValue,
        tractor_id: event.value.extendedProps.sameTime != '' ? satTractor : friTractor,
      },
    ];

    var StartDateFrom   = Timings[0].day;
    var NewDayStartDate = new Date(StartDateFrom);
    var EndDateTo       = moment(NewDayStartDate.setDate(NewDayStartDate.getDate() + 6)).format("YYYY-MM-DD");

    if (StartDateFrom && EndDateTo) 
    {
        const start = new Date(StartDateFrom);
        const end = new Date(EndDateTo);
        const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
        
        // const TotalDays = parseInt(days)  +1;

        const TotalDays = 7;

        var dateArray = [];
        for (let i = 0; i < TotalDays; i++) 
        {
            const currentDate = new Date(start.getTime() + (i * 24 * 60 * 60 * 1000));
            dateArray.push(
              {
                day:moment(currentDate).format('YYYY-MM-DD')+' '+Timings[i].time,
                time:Timings[i].time,
                tractor_id:Timings[i].tractor_id
              });
        }

        Timings = dateArray;
    }

    eventData.timings = Timings.filter((e)=>e.tractor_id !== ""  );

    store.dispatch('calendar/addEvent', { event: eventData }).then((response) => {

      if (response.data.status) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Schedule added successfully.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })


        // eslint-disable-next-line no-use-before-define
        refetchEvents()

      } else {

        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })

      }

    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {

      if(response.data.status == false)
      {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      } else {

        toast({
          component: ToastificationContent,
          props: {
            title: 'Schedule Updated sucessfully.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }

      refetchEvents()

      // console.log(response);
      // const updatedEvent = response.data.event

      // const propsToUpdate = ['id', 'title', 'start']
      // const extendedPropsToUpdate = ['domicile','route','driver']

      // updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id

      store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
        removeEventInCalendar(eventId)
      })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
      })
      .then(response => {

        // const { userData } = response.data;

        localStorage.setItem('currentSchedule', JSON.stringify(response.data));

        EventBus.$emit('getCurrentWeekSchedules', {data:response.data});

        successCallback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    firstDay: localStorage.getItem('start_day') == undefined ? 6 : localStorage.getItem('start_day'),
    events: fetchEvents,
    scrollTime: '00:00:00',
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,
    displayEventTime: true,
    allDaySlot: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: false,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 100,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,
    weekNumbers : true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-primary`,
      ]
    },
    eventClick({ event: clickedEvent }) {

      console.log('event clicked');
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent);

      var getDay = moment(new Date(clickedEvent.start)).format("ddd");

      if (getDay == 'Mon') {
        event.value.extendedProps.mon_value = event.value.extendedProps.time;
        event.value.extendedProps.mon_tractor_id = event.value.extendedProps.vehicle;


      } else if (getDay == 'Tue') {
        event.value.extendedProps.tue_value = event.value.extendedProps.time;
        event.value.extendedProps.tue_tractor_id = event.value.extendedProps.vehicle;

      } else if (getDay == 'Wed') {
        event.value.extendedProps.wed_value = event.value.extendedProps.time;
        event.value.extendedProps.wed_tractor_id = event.value.extendedProps.vehicle;

      } else if (getDay == 'Thu') {
        event.value.extendedProps.thu_value = event.value.extendedProps.time;
        event.value.extendedProps.thu_tractor_id = event.value.extendedProps.vehicle;

      } else if (getDay == 'Fri') {
        event.value.extendedProps.fri_value = event.value.extendedProps.time;
        event.value.extendedProps.fri_tractor_id = event.value.extendedProps.vehicle;

      } else if (getDay == 'Sat') {
        event.value.extendedProps.sat_value = event.value.extendedProps.time;
        event.value.extendedProps.sat_tractor_id = event.value.extendedProps.vehicle;

      } else {
        event.value.extendedProps.sun_value = event.value.extendedProps.time;
        event.value.extendedProps.sun_tractor_id = event.value.extendedProps.vehicle;
      }

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true

      EventBus.$emit('getDropDownData', {data:event.value,day:getDay});
      // EventBus.$emit('resetFormData', {type:'edit'});

    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      prev: { // this overrides the prev button
        text: "prev", 
        click: (event) => {   
          var s = new Date(calendarApi.view.currentStart);
          var e = new Date(calendarApi.view.currentStart);

          var Start = moment(s.setDate(s.getDate() - 7)).format("YYYY-MM-DD") + " 00:00:00";
          var End   = moment(e.setDate(e.getDate() - 1)).format("YYYY-MM-DD") + " 00:00:00";

          localStorage.setItem('start_week',Start);
          localStorage.setItem('end_week',End);

          EventBus.$emit('getSelectedWeekDetails', {start:Start,end:End});
          calendarApi.prev();
        }
      },
      next: { // this overrides the next button
        text: "next",
        click: (event) => {

          var s = new Date(calendarApi.view.currentStart);
          var e = new Date(calendarApi.view.currentStart);
          

          var Start = moment(s.setDate(s.getDate() + 7)).format("YYYY-MM-DD") + " 00:00:00";
          var End   = moment(e.setDate(e.getDate() + 13)).format("YYYY-MM-DD") + " 00:00:00";

          localStorage.setItem('start_week',Start);
          localStorage.setItem('end_week',End);

          EventBus.$emit('getSelectedWeekDetails', {start:Start,end:End});
          calendarApi.next();
        }
      }
    },

    dateClick(info) {
      
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date})))
      
      if (event.value.start == '') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Please try again! Refersh the page',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } 
    
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true

      EventBus.$emit('resetFormData', {type:'add'});

    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
